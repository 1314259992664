import NameTamil from '../assets/feedback_voice/Name_tamil.mp3';
import FeedbackTamil from '../assets/feedback_voice/Feedback_tamil.mp3';
import YourName from '../assets/feedback_voice/Your_Name.mp3';
import YourFeedback from '../assets/feedback_voice/Your_Feedback.mp3';
import WelcomTamil from '../assets/feedback_voice/Welcome_tamil_50_volume.mp3';
export default {
    MESSAGE: {
        CONTENT: 'எங்கள் உதவியாளரிடம் பேசுங்கள்!',
        SELECT_LANGUAGE: 'Choose Language / மொழித் தேர்வு',
        CHANGE_LANGUAGE: 'மொழியை மாற்ற',
        FULLNAME: "நோயாளியின் பெயர் / UHID ",
        NAME: "நோயாளியின் பெயர்",
        MOBILE_NUMBER: "கைபேசி எண் ",
        RECOMMEND_QUESTION: "SRM குளோபல் மருத்துவமனையை நண்பர் அல்லது குடும்ப உறுப்பினருக்கு பரிந்துரைக்கிறீர்களா?",
        YES: "ஆம்",
        NO: "இல்லை",
        PLEASE_SELECT_THE_OPTION: "விருப்பத்தைத் தேர்ந்தெடுக்கவும்",
        ENTER_MOBILE_NUMBER: "கைபேசி எண்ணை உள்ளிடவும்",
        PLEASE_ENTER_THE_MOBLIE_NUMBER: "கைபேசி எண்ணை உள்ளிடவும்",
        MOBILE_NUMBER_MUST_BE_10_CHARCTER: "சரியான கைபேசி எண் உள்ளிடவும்",
        COMMING_SOON: "விரைவில்!",
        CATEGORY: "கருத்து வகை",
        LIST_OF_CATEGORY: [
            {
                id: '01',
                icon: 'fal fa-credit-card',
                value: 'பில்லிங் மற்றும் பேமெண்ட்',
                string: 'BILLING_AND_PAYMENT'
            },
            {
                id: '02',
                icon: 'fal fa-user-nurse',
                value: 'நர்சிங் ஊழியர்கள்',
                string: 'NURSING_STAFF'
            },
            {
                id: '03',
                icon: 'fal fa-prescription-bottle-medical',
                value: 'மருந்தக சேவைகள்',
                string: 'PHARMACY_SERVICES'
            },
            {
                id: '04',
                icon: "fal fa-broom",
                value: "சுத்தப்படுத்தும் சேவைகள்",
                string: 'CLEANING_SERVICES'
            },
            {
                id: '05',
                icon: 'fal fa-user-tie',
                value: 'நிர்வாக சேவைகள்',
                string: 'ADMINISTRATIVE_SERVICES'
            },
            {
                id: '06',
                icon: 'fal fa-flask',
                value: 'ஆய்வக சேவைகள்',
                string: 'LABORATORY_SERVICES'
            },
        ],
        LIST_OF_REASON: {
            APPOINTMENT: "முன்பதிவு",
            NAVIGATION: "வழிகாட்டி",
            PATIENT_VISITING: "நோயாளியை காண"
        },
        SELECT_REASON: 'காரணத்தைத் தேர்ந்தெடுக்கவும்',
        SELECT_CATEGORY: 'வகையைத் தேர்ந்தெடுக்கவும்',
        ENTER_NAME: "பெயரை உள்ளிடவும்",
        REASON: "காரணம்",
        CALL_NOW: "அழைப்பை தொடரவும்",
        PLEASE_ENTER_THE_NAME: "தயவுசெய்து பெயரை உள்ளிடவும்",
        FULL_NAME_MINIMUM_CHARACTER: "முழுப்பெயரில் குறைந்தது 3 எழுத்துகள் இருக்க வேண்டும்.",
        PLEASE_SELECT_THE_REASON: "தயவுசெய்து காரணத்தைத் தேர்ந்தெடுக்கவும்",
        PLEASE_SELECT_THE_CATEGORY: "தயவுசெய்து வகையைத் தேர்ந்தெடுக்கவும்",
        NAME_REGX: "சிறப்பு எழுத்துகள் & எண்கள் அனுமதிக்கப்படாது",
        MIC: {
            UNMUTE: "மைக்கை இணைக்க",
            MUTE: "மைக் தடுக்கவும்"
        },
        VIDEO: {
            UNMUTE: "வீடியோ இணைக்க",
            MUTE: "வீடியோ தடுக்கவும்"
        },
        END_CALL: "இணைப்பை துண்டிக்க",
        CALL_FEEDBACK: {
            FEEDBACK: "உங்கள் கருத்து",
            FEEDBACK_HEADERS: "இரண்டு நிமிடங்கள் ஒதுக்கி உங்களுக்குச் சிறப்பாகச் சேவை செய்ய எங்களுக்கு உதவுங்கள், எனவே உங்கள் கருத்தை நாங்கள் கேட்க விரும்புகிறோம்",
            RATE_YOUR_EXPERIENCE: "உங்கள் அனுபவத்தை மதிப்பிடுங்கள்",
            COMMMENT_OPTIONAL: "கருத்துகள் ",
            OPTIONAL: "(விரும்பினால்)",
            YOUR_COMMENTS: "தங்கள் கருத்து",
            SEND_FEEDBACK: "கருத்தினை அனுப்பவும்",
            SKIP: "தவிர்க்கவும்",
        },
        BACK: "முந்தைய பக்கத்திற்குச் செல்ல",
        FEEDBACK: "உங்கள் கருத்தைத் தெரிவிக்கவும்",
        CUSTOMER_FEEDBACK: 'உங்கள் கருத்து',
        FEEDBACK_CONTENT: "எங்கள் அமைப்பை மேம்படுத்த தொடர்ந்து பாடுபடுவதால், உங்கள் கருத்து எங்களுக்கு விலைமதிப்பற்றது",
        CHOOSE_FEEDBACK: 'உங்கள் கருத்து விருப்பங்களைத் தேர்ந்தெடுக்கவும்',
        AUDIO: "குரலின் மூலம் உங்கள் கருத்துக்களை பகிரவும்",
        AUDIO_CONTENT: "குரலின் மூலம் உங்கள் எண்ணங்களையும் பகிரவும்",
        TEXT: "எழுத்துக்களின் மூலம் உங்கள் பரிந்துரைகளை பகிரவும்",
        TEXT_CONTENT: "எங்கள் சேவைகளை வடிவமைக்க எழுத்தில் உங்கள் கருத்தைப் பகிரவும்",
        NAME_AUDIO: NameTamil,
        FEEDBACK_AUDIO: FeedbackTamil,
        YOUR_NAME: YourName,
        YOUR_FEEDBACK: YourFeedback,
        WELCOME: WelcomTamil,
        NEXT: "அடுத்தது",
        SUBMIT: "சமர்ப்பிக்கவும்",
        CANCEL: "ரத்து செய்ய",
        SUCCESSFULLY_SUBMITTED: 'உங்கள் கருத்து வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது',
        FEEDBACK_FOR_HOSPITAL: "OP கருத்து படிவம்",
        PLEASE_SELECT_THE_FEEDBACK: "தயவுசெய்து தேவையான அனைத்து கருத்து விருப்பத்தைத் தேர்வு செய்யவும்",
        FEEDBACK_QUESTIONS: {
            "BILLING_AND_PAYMENT": [
                {
                    question: 'SRM மருத்துவமனை வழங்கிய பில்லிங் மற்றும் கட்டணச் சேவைகள் எவ்வளவு திருப்திகரமாக இருந்தது?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
                {
                    question: 'உங்கள் பில்லில் உள்ள கட்டணங்களின் முறிவை உங்களால் புரிந்து கொள்ள முடிந்ததா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'தெளிவானது' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'தெளிவற்றது' },
                    ],
                },
                {
                    question: 'SRM மருத்துவமனையில் பணம் செலுத்தும் செயல்முறை வசதியாக இருப்பதாக நீங்கள் கண்டீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
            ],
            "NURSING_STAFF": [
                {
                    question: 'நீங்கள் SRM மருத்துவமனையில் தங்கியிருந்த போது நர்சிங் ஊழியர்கள் வழங்கிய கவனிப்பில் நீங்கள் எவ்வளவு திருப்தி அடைந்தீர்கள்?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
                {
                    question: 'நர்சிங் ஊழியர்களால் வழங்கப்பட்ட அறிவுறுத்தல்கள் மற்றும் தகவல்களின் தெளிவு உங்களுக்கு திருப்தி அளிக்குமா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'தெளிவானது' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'தெளிவற்றது' },
                    ],
                },
                {
                    question: 'உங்கள் தேவைகளுக்கு செவிலியர் பணியாளர்கள் பதிலளிப்பதை நீங்கள் கண்டீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
            ],
            "PHARMACY_SERVICES": [
                {
                    question: 'SRM மருத்துவமனை வழங்கும் மருந்தகச் சேவைகள் எவ்வளவு திருப்திகரமாக இருந்தது?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
                {
                    question: 'மருந்து தகவலை வழங்குவதற்கு மருந்தக ஊழியர்கள் உதவியாக இருப்பதைக் கண்டீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
                {
                    question: 'மருந்தகத்தில் மருந்துகள் கிடைப்பதில் நீங்கள் திருப்தி அடைந்தீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
            ],
            "CLEANING_SERVICES": [
                {
                    question: 'நீங்கள் SRM மருத்துவமனைக்குச் சென்றபோது, வசதிகளின் தூய்மையில் எவ்வளவு திருப்தி அடைந்தீர்கள்?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
                {
                    question: 'நீங்கள் SRM மருத்துவமனையில் தங்கியிருந்த காலத்தில் சுகாதாரத் தரங்கள் திருப்திகரமாக இருப்பதைக் கண்டீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
                {
                    question: 'துப்புரவு சேவைகளுக்கான உங்கள் கோரிக்கைகள் மருத்துவமனை ஊழியர்களால் உடனடியாக கவனிக்கப்பட்டதா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'ஆம்' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'இல்லை' },
                    ],
                },
            ],
            "ADMINISTRATIVE_SERVICES": [
                {
                    question: 'SRM மருத்துவமனை வழங்கும் நிர்வாகச் சேவைகள் எவ்வளவு திருப்திகரமாக இருந்தது?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
                {
                    question: 'SRM மருத்துவமனையில் சந்திப்பு திட்டமிடல் செயல்முறையின் செயல்திறனில் நீங்கள் திருப்தி அடைந்தீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'ஆம்' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'இல்லை' },
                    ],
                },
                {
                    question: 'உங்கள் உரையாடலின் போது நிர்வாகப் பணியாளர்கள் கண்ணியமாகவும் உதவிகரமாகவும் இருப்பதைக் கண்டீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'ஆம்' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'இல்லை' },
                    ],
                },
            ],
            "LABORATORY_SERVICES": [
                {
                    question: 'SRM மருத்துவமனை வழங்கிய ஆய்வகச் சேவைகள் எவ்வளவு திருப்திகரமாக இருந்தது?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'திருப்தி' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'அதிருப்தி' },
                    ],
                },
                {
                    question: 'ஆய்வகம் வழங்கிய சோதனை முடிவுகளின் துல்லியம் மற்றும் நம்பகத்தன்மை குறித்து நீங்கள் திருப்தி அடைந்தீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'ஆம்' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'இல்லை' },
                    ],
                },
                {
                    question: 'ஆய்வகத்திலிருந்து சோதனை அறிக்கைகளைப் பெறுவதற்கான நேரம் திருப்திகரமாக இருப்பதைக் கண்டீர்களா?',
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: 'ஆம்' },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: 'நடுநிலை' },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: 'இல்லை' },
                    ],
                },
            ],
            "SRM_HOSPITAL_CONTENT": "சேவையில் எவ்வளவு திருப்தி அடைந்தீர்கள்?",
            "SRM_HOSPITAL": [
                {
                    question: "நியமனம் பெறுதல்",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
                {
                    question: "பதிவு செயல்முறை",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
                {
                    question: "OPD காத்திருப்புப் பகுதியில் காத்திருக்கும் நேரம்",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
                {
                    question: "மருத்துவரின் ஆலோசனை",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
                {
                    question: "மருத்துவமனை ஊழியர்களின் நடத்தை",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
                {
                    question: "ஒட்டுமொத்த தூய்மை",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
                {
                    question: "ஒட்டுமொத்த வெளிநோயாளர் அனுபவம்",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
                {
                    question: "மருந்தக சேவைகள் (விநியோக நேரம் மற்றும் மருந்துகள் கிடைக்கும்)",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
                {
                    question: "ஆய்வக சேவைகள்",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                }, {
                    question: "கதிரியக்க சேவைகள் (நியமன நேரம் மற்றும் சரியான நேரத்தில் அறிக்கைக்கு இணங்குதல்)",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "சிறப்பானது" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "மிகவும் நல்லது" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "நல்லது" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "நடுத்தரம்" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "முன்னேற்றம் தேவை" },
                    ],
                },
            ],
        },
        AUTO_RECORD: "தங்கள் பதிலை தானாக பதிவு செய்யும்",
        FIRST_QUESTION: "தயவு செய்து தங்கள் பெயரை கூறவும்.",
        SECOND_QUESTION: "தயவுசெய்து தங்கள் அனுபவத்தை கூறவும்.",
        RECORDER_ANSWER: "உங்கள் பதிவு செய்யப்பட்ட பதிலைக் கேளுங்கள்",
        SELECTED_QUESTION: { 7: "பொருந்தாது", 8: "பொருந்தாது", 9: "பொருந்தாது" },
        HOW_CAN_I_HELP: "நான் உங்களுக்கு எப்படி உதவ முடியும்?",
        SERVICES: "எங்கள் சேவைகள்",
        FEEDBACK_CANCEL: {
            desc: "கருத்தை ரத்து செய்ய விரும்புகிறீர்களா?",
            proceed: "ஆம்",
            cancel: "இல்லை ",
        }

    }
};