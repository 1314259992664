import NameEnglish from "../assets/feedback_voice/Name_english.mp3";
import FeedbackEnglish from "../assets/feedback_voice/Feedback_english.mp3";
import YourName from "../assets/feedback_voice/Your_Name.mp3";
import YourFeedback from "../assets/feedback_voice/Your_Feedback.mp3";
import WelcomEnglish from "../assets/feedback_voice/Welcome_english_50_volume.mp3";
export default {
    MESSAGE: {
        CONTENT: "Talk to our assistant!",
        SELECT_LANGUAGE: "Choose Language / மொழித் தேர்வு",
        CHANGE_LANGUAGE: "Change Language",
        FULLNAME: "Name of the patient / UHID ",
        NAME: "Name",
        MOBILE_NUMBER: "Mobile Number ",
        RECOMMEND_QUESTION: "Would you recommend SRM Global hospital to a friend or family member?",
        YES: "Yes",
        NO: "No",
        PLEASE_SELECT_THE_OPTION: "Please Select the option",
        ENTER_MOBILE_NUMBER: "Enter the Mobile Number",
        PLEASE_ENTER_THE_MOBLIE_NUMBER: "Please enter the mobile number",
        MOBILE_NUMBER_MUST_BE_10_CHARCTER: "Please enter a valid mobile number",
        COMMING_SOON: "Coming Soon!",
        CATEGORY: "Category",
        LIST_OF_CATEGORY: [
            {
                id: "01",
                icon: "fal fa-credit-card",
                value: "Billing and Payment",
                string: "BILLING_AND_PAYMENT",
            },
            {
                id: "02",
                icon: "fal fa-user-nurse",
                value: "Nursing Staff",
                string: "NURSING_STAFF",
            },
            {
                id: "03",
                icon: "fal fa-prescription-bottle-medical",
                value: "Pharmacy Services",
                string: "PHARMACY_SERVICES",
            },
            {
                id: "04",
                icon: "fal fa-broom",
                value: "Cleaning Services",
                string: "CLEANING_SERVICES",
            },
            {
                id: "05",
                icon: "fal fa-user-tie",
                value: "Administrative Services",
                string: "ADMINISTRATIVE_SERVICES",
            },
            {
                id: "06",
                icon: "fal fa-flask",
                value: "Laboratory Services",
                string: "LABORATORY_SERVICES",
            },
        ],
        LIST_OF_REASON: {
            APPOINTMENT: "Appointment",
            NAVIGATION: "Navigation",
            PATIENT_VISITING: "Patient Visiting",
        },
        SELECT_REASON: "Select reason",
        SELECT_CATEGORY: "Select category",
        ENTER_NAME: "Enter the name",
        REASON: "Reason",
        CALL_NOW: "Call Now",
        PLEASE_ENTER_THE_NAME: "Please enter the name",
        FULL_NAME_MINIMUM_CHARACTER: "Full name should contain miniumum 3 characters.",
        PLEASE_SELECT_THE_REASON: "Please select the reason",
        PLEASE_SELECT_THE_CATEGORY: "Please select the category",
        NAME_REGX: "Special characters & numbers are not allowed",
        MIC: {
            UNMUTE: "UnMute Mic",
            MUTE: "Mute Mic",
        },
        VIDEO: {
            UNMUTE: "Un Mute Video",
            MUTE: "Mute Video",
        },
        END_CALL: "End Call",
        CALL_FEEDBACK: {
            FEEDBACK: "FEEDBACK",
            FEEDBACK_HEADERS: "Please help us to serve you better by taking a couple of minutes,so we'd like to hear your feedback.",
            RATE_YOUR_EXPERIENCE: "Rate your experience",
            COMMMENT_OPTIONAL: "Comments ",
            OPTIONAL: "(optional)",
            YOUR_COMMENTS: "Your comment",
            SEND_FEEDBACK: "Send feedback",
            SKIP: "Skip",
        },
        BACK: "Back",
        FEEDBACK: "Give your feedback",
        CUSTOMER_FEEDBACK: 'Feedback',
        FEEDBACK_CONTENT: "Your feedback is invaluable to us as we continuously strive to enhance our system",
        CHOOSE_FEEDBACK: "Choose your feedback options",
        AUDIO: "Feedback over voice",
        AUDIO_CONTENT: "Share your thoughts and suggestions through audio input",
        TEXT: "Feedback through text",
        TEXT_CONTENT: "Share your feedback in writing to shape our services",
        NAME_AUDIO: NameEnglish,
        FEEDBACK_AUDIO: FeedbackEnglish,
        YOUR_NAME: YourName,
        YOUR_FEEDBACK: YourFeedback,
        WELCOME: WelcomEnglish,
        NEXT: "Next",
        SUBMIT: "Submit",
        CANCEL: "Cancel",
        SUCCESSFULLY_SUBMITED: "Your feedback successfully submitted",
        FEEDBACK_FOR_HOSPITAL: "OP FEEDBACK FORM",
        PLEASE_SELECT_THE_FEEDBACK: "Please select all the required feedback options",
        FEEDBACK_QUESTIONS: {
            BILLING_AND_PAYMENT: [
                {
                    question: "How satisfied were you with the billing and payment services provided by SRM Hospital?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
                {
                    question: "Were you able to understand the breakdown of charges on your bill?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Clear" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Unclear" },
                    ],
                },
                {
                    question: "Did you find the payment process at SRM Hospital convenient?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
            ],
            NURSING_STAFF: [
                {
                    question: "How satisfied were you with the care provided by the nursing staff during your stay at SRM Hospital?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
                {
                    question: "Were you satisfied with the clarity of instructions and information provided by the nursing staff?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Clear" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Unclear" },
                    ],
                },
                {
                    question: "Did you find the nursing staff responsive to your needs?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
            ],
            PHARMACY_SERVICES: [
                {
                    question: "How satisfied were you with the pharmacy services provided by SRM Hospital?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
                {
                    question: "Did you find the pharmacy staff helpful in providing medication information?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
                {
                    question: "Were you satisfied with the availability of medications at the pharmacy?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
            ],
            CLEANING_SERVICES: [
                {
                    question: "How satisfied were you with the cleanliness of the facilities during your visit to SRM Hospital?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
                {
                    question: "Did you find the hygiene standards satisfactory during your stay at SRM Hospital?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
                {
                    question: "Were your requests for cleaning services addressed promptly by the hospital staff?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Yes" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "No" },
                    ],
                },
            ],
            ADMINISTRATIVE_SERVICES: [
                {
                    question: "How satisfied were you with the administrative services provided by SRM Hospital?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
                {
                    question: "Were you satisfied with the efficiency of the appointment scheduling process at SRM Hospital?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Yes" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "No" },
                    ],
                },
                {
                    question: "Did you find the administrative staff courteous and helpful during your interactions?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Yes" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "No" },
                    ],
                },
            ],
            LABORATORY_SERVICES: [
                {
                    question: "How satisfied were you with the laboratory services provided by SRM Hospital?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Satisfied" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "Dissatisfied" },
                    ],
                },
                {
                    question: "Were you satisfied with the accuracy and reliability of the test results provided by the laboratory?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Yes" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "No" },
                    ],
                },
                {
                    question: "Did you find the turnaround time for receiving test reports from the laboratory satisfactory?",
                    options: [
                        { id: "1", icon_active: "fa fa-smile text-success", icon: "fal fa-smile text-success", rate: 3, value: "Yes" },
                        { id: "2", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 2, value: "Neutral" },
                        { id: "3", icon_active: "fa fa-frown text-danger", icon: "fal fa-frown text-danger", rate: 1, value: "No" },
                    ],
                },
            ],
            SRM_HOSPITAL_CONTENT: "How satisfied were you with the service?",
            SRM_HOSPITAL: [
                {
                    question: "Getting Appointment",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
                {
                    question: "Registration Process",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
                {
                    question: "Waiting Time in OPD waiting area",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
                {
                    question: "Doctor's consultation",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
                {
                    question: "Hospital staff behavior",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
                {
                    question: "Overall cleanliness",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
                {
                    question: "Overall Outpatient Experience",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
                {
                    question: "Pharmacy Services (Dispensing time & availability of drugs)",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
                {
                    question: "Laboratory Services",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                }, {
                    question: "Radiology Services (Adherence to appointment time and timely report)",
                    options: [
                        { id: "1", icon_active: "fas fa-laugh-beam text-success", icon: "fal fa-laugh-beam text-success", rate: 5, value: "Excellent" },
                        { id: "2", icon_active: "fa fa-smile text-info", icon: "fal fa-smile text-info", rate: 4, value: "V Good" },
                        { id: "3", icon_active: "fa fa-meh text-warning", icon: "fal fa-meh text-warning", rate: 3, value: "Good" },
                        { id: "4", icon_active: "fa fa-frown text-average", icon: "fal fa-frown text-average", rate: 2, value: "Average" },
                        { id: "5", icon_active: "fas fa-tired text-danger", icon: "fal fa-tired text-danger", rate: 1, value: "Needs Improvement" },
                    ],
                },
            ],
        },
        AUTO_RECORD: "Auto recording your answer",
        FIRST_QUESTION: "Could you please tell me your name?",
        SECOND_QUESTION: "Could you please give your feedback?",
        RECORDER_ANSWER: "Listen to your recorded answer",
        SELECTED_QUESTION: { 7: "Not applicable", 8: "Not applicable", 9: "Not applicable" },
        HOW_CAN_I_HELP: "How can i help you?",
        SERVICES: "Our Services",
        FEEDBACK_CANCEL: {
            desc: "Do you want to cancel the feedback?",
            proceed: "Yes",
            cancel: "No",
        }
    },
};
